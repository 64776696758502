import React, { useEffect } from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const StockClearance = () => {

    const navigate = useNavigate();
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "",
        },
        {
            text: " Stock Clearance",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <Helmet>
                <title>Limited Time Stock Clearance Offers | W-Hanson UK</title>
                <meta name="description" content="Take advantage of limited time offers on stock clearance at W-Hanson UK. Save big on a variety of items. Hurry, while stocks last." />
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="container">
                        <div className='text-center col-9 mt-5 mb-5 pt-5 pb-5 bd-join' style={{ margin: "auto" }}>
                            <h1 style={{ fontSize: "44px", marginBottom: '20px' }}>Clearance Corner Sheet Materials.</h1>
                            <h2 style={{ fontSize: "30px", marginBottom: '25px' }}>Both our Iron Bridge & Harrow depots have a large clearance corner section of damaged sheet material that we are keen to shift.</h2>
                            <h3 style={{ fontSize: "25px", marginBottom: '25px' }}><strong>Stunning deals</strong> ready to be done.</h3>
                            <h3 style={{ fontSize: "25px", marginBottom: '25px' }}>Speak to one of our Sales Team now! </h3>

                            <p style={{ fontSize: "20px", marginBottom: '15px' }}>Iron Bridge : 020 8571 3161</p>
                            <p style={{ fontSize: "20px", marginBottom: '15px' }}>Harrow   :  020 8864 0822</p>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default StockClearance