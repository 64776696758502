import React, { useEffect, useState } from 'react'
import PageContainer from '../../components/layouts/PageContainer';
import BreadCrumb from '../../components/elements/BreadCrumb';
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers//FooterMarketPlace2';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import Loader from '../Loader';


export const blogs_data = [
    {
        title: 'The Future of Timber in Sustainable Construction',
        img: "https://plus.unsplash.com/premium_photo-1683134181132-d21384512e01?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        subtitle: 'This blog can discuss the role of timber in green building projects, exploring its benefits in reducing carbon footprints, sustainable timber sourcing.',
        slug: 'the-future-of-timber-in-sustainable-construction'
    },
    {
        title: 'Choosing the Right Construction Materials for Your Next Project',
        img: "https://plus.unsplash.com/premium_photo-1683141600497-e27591786d0d?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        subtitle: `This blog will focus on the selection process for construction materials, highlighting timber, steel, concrete, and other building materials.`,
        slug: 'choosing-the-right-construction-materials-for-your-next-project'
    },
    {
        title: 'Timber Framing: A Timeless Building Technique for Modern Homes',
        img: "https://plus.unsplash.com/premium_photo-1683140664559-f6b9372a7a0a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        subtitle: 'In this post, explore the resurgence of timber framing as a building method. Discuss its aesthetic appeal, durability.',
        slug: 'timber-framing-a-timeless-building-technique-for-modern-homes'
    },
    {
        title: 'Building with Timber: Best Practices for Contractors',
        img: "https://plus.unsplash.com/premium_photo-1681691757916-8c60c2247a0a?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        subtitle: 'This blog can target contractors and builders, offering tips on how to work with timber effectively. Topics can include moisture control.',
        slug: 'building-with-timber-best-practices-for-contractors'
    },
    {
        title: 'Innovations in Timber Construction: New Products Shaping the Industry',
        img: "https://plus.unsplash.com/premium_photo-1726804923004-e10ffd8fdf6c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        subtitle: 'Focus on the latest innovations in timber products such as engineered wood, prefabricated timber panels, and hybrid systems that combine timber.',
        slug: 'innovations-in-timber-construction-new-products-shaping-the-industry'
    }
];

const Blog = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        getBlogs()
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: "instant"})
        }, 500)
    }, [])

    async function getBlogs() {
        try {
            setLoading(true)
            const response = await axios.get(`${config.apiUrl}/blogs`)
            setData(response.data.blog)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "Blogs",
        },
    ];

    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <>
            {loading && <Loader />}
            <Helmet>
                <title>Blogs | W-hanson UK</title>
                <meta name="description" content="Blog Posts" />
                <meta name="keywords" content="timber building materials, timber building materials near me,timber sheets" />
            </Helmet>

            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <BreadCrumb breacrumb={breadCrumb} />


                    <div className="ps-section--custom">
                        <div className="container">
                            <div className='row '>
                                {
                                    data?.map((blog) => {
                                        return <BlogCard key={blog.id} blog={blog} />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </PageContainer>


        </ >
    )
}

const BlogCard = ({ blog }) => {
    return (
        <div className="col-md-4 mb-5">
            <div className="blog_card">
                <div>
                    <Link to={`/blog/${blog.slug}`}>
                        <img src={blog.imageUrl} alt={blog.title} />
                    </Link>
                </div>
                <div className='p-4'>
                    <h3>{blog.title}</h3>
                    {/* <div dangerouslySetInnerHTML={{ __html: blog?.description }} /> */}
                    <p className='mb-0 mt-3'>
                        {blog?.description?.replace(/<[^>]+>/g, '').slice(0, 120)}
                        {blog?.description?.replace(/<[^>]+>/g, '').length > 120 && '...'}
                    </p>
                </div>
                <div className='d-flex align-items-center justify-content-between p-4'>
                    <span>{moment(blog.created_at).format("DD, MMMM YYYY")}</span>
                    <div className='d-flex align-items-center justify-content-end' style={{ flex: 1 }}>
                        <div>
                            <Link to={`/blog/${blog.slug}`} > Read More.. </Link>
                        </div>
                        <div className='ml-10 py-2 px-3 bg-white shadow-sm rounded-circle' >
                            <Link to={`/blog/${blog.slug}`} ><i className='fa fa-arrow-right'></i> </Link>

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Blog