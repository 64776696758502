import React, { useEffect } from 'react'
import HeaderMarketPlace from '../../components/shared/headers/HeaderMarketPlace';
import HeaderMobile from '../../components/shared/headers/HeaderMobile';
import FooterMarketPlace2 from '../../components/shared/footers/FooterMarketPlace2';
import BreadCrumb from '../../components/elements/BreadCrumb';
import PageContainer from '../../components/layouts/PageContainer';
import DownloadPDf from "../../assets/static/img/Account Application.doc"
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

const FAQ = () => {

    const navigate = useNavigate();
    // useEffect(() => {
    //     if (
    //         !localStorage.getItem("auth_user")
    //     ) {
    //         navigate("/login");
    //     }
    // }, []);

    const breadCrumb = [
        {
            text: "Home",
            url: "/",
        },
        {
            text: "FAQs",
        },
    ];
    const headers = (
        <>
            <HeaderMarketPlace />
            <HeaderMobile />
        </>
    );
    const footer = <FooterMarketPlace2 />;

    return (
        <div>
            <Helmet>
                <title>Get Expert Answers on Timber FAQs | W-Hanson UK</title>
                <meta name="description" content="Explore comprehensive answers on timber FAQs at W-Hanson UK. Get clarity on all aspects of timber products and services offered."></meta>
            </Helmet>
            <PageContainer header={headers} footer={footer} title="Register">
                <div className="ps-page--single">
                    <BreadCrumb breacrumb={breadCrumb} />
                    <div className="container">
                        <div className='mt-5 pt-4 mb-3'>
                            <h2>Welcome to the question & answer section.</h2>
                            <p style={{ fontSize: "17px" }}>The list of questions below are intended to answer the most commonly asked questions - this will hopefully be the quickest way to find out information about our company.</p>
                        </div>
                        <div className="table-responsive">
                            <table className="table ps-table--faqs">
                                <tbody>
                                    <tr>
                                        <td className="question">
                                            Q) What are your contact details & opening hours?
                                        </td>
                                        <td>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <h4 style={{ color: "#07395c" }}>W.Hanson (Iron Bridge) Ltd</h4>
                                                    <p className='mb-0'>Uxbridge Road , Southall , Middlesex , UB1 3EQ</p>
                                                    <p className='mb-0'> Tel: 020 8571 3161 , Fax: 020 8574 3816</p>
                                                    <p>Mon-Fri 7.00am - 5.15pm , Sat 8.00am - 2.00pm</p>
                                                </div>
                                                <div className='col-6'>
                                                    <h4 style={{ color: "#07395c" }}>W.Hanson (Harrow) Ltd</h4>
                                                    <p className='mb-0'>92-108 Greenford Road , Harrow ,Middlesex , HA1 QL</p>
                                                    <p className='mb-0'>Tel: 020 8864 0822 , Fax: 020 8423 5755</p>
                                                    <p>Mon-Fri 6.30am - 5.00pm , Sat 7.00am - 12.00pm</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">
                                            Q) Do you deliver free of charge?
                                        </td>
                                        <td>
                                            Yes we do. As long as your order is over £50 in value, and within ........postcodes then we will deliver for free. If your order is under £50 then we will charge a £10 + vat delivery charge.
                                            Please contact our sales office for information about deliveries outside of these postcodes.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">
                                            Q)  How far do you deliver?
                                        </td>
                                        <td>
                                            We deliver throughout London & the South East. We currently have contracts in East & West Sussex, Barking, Reading, Aylesbury, Northampton and Surrey. As a company, we have made the decision not to limit our services as much as we can.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">Can I have a same day delivery?</td>
                                        <td>
                                            Because of the distances we travel, our fleet of trucks normally leave the yard in the morning and don't return until the afternoon. But sometimes we our trucks do stay local, so there is always a chance we can do a same day delivery. Please contact our sales team who will be able to advise you.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">Q) Do you care about the environment? </td>
                                        <td>
                                            Yes, very much so! Over the last few years, the W.Hanson Group has endeavoured to purchase as much materials as possible from sustainable sources. We have had 'Chain of Custody' for both FSC & PEFC for several years to ensure that our products come from sustainable sources. Please see our Environment page for our environmental policy.
                                        </td>
                                    </tr>
                                    <tr>

                                        <td className="question">Q) Do you machine timber to order?</td>
                                        <td>
                                            Yes. We have a mill with a couple of spindle moulders, a five cutter moulder, thichnessers, resaws, overhands & cross cuts. We can match almost any skirting or architrave moulding that you may require.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">
                                            Q) I have some skirting that I need to replace - can you match it?
                                        </td>
                                        <td>
                                            Yes. We can have cutters made to match your skirting and machine in your choice of timber using our spindle moulders or five cutter moulder.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">Q) Do you supply both trade & retail?</td>
                                        <td>
                                            Yes. We have nearly 60 years experience of dealing with both trade & retail customers.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">Q) Do you offer better rates & discounts for larger orders?</td>
                                        <td>
                                            Yes we do. While our prices are already very competitive, we do offer even more competitive prices based on quantity.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">Q) I can't see what I am looking for on your website, can you still source what I need?</td>
                                        <td>
                                            Absolutely! We are constantly trying to keep our web site upto date, but with so many products in stock this is not always easy.
                                            Also, we are of the mindset that 'if the customer wants something - we will get hold of it for you!' There is very little we can't get hold of for you!
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="question">Q) I am starting a self build project. Can I open a credit account with you?</td>
                                        <td>
                                            Yes you can. This is the easiest way to trade with us. Click here to download our account application form. <br />
                                            <a href={DownloadPDf} target='_blank' download>Account Application.doc</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default FAQ